import throttle from 'lodash/throttle';

document.addEventListener('DOMContentLoaded', () => {
	const block = 'b-header'
	
	const header = document.querySelector(`.${block}`)
	const menuButton = document.querySelector(`.${block}__menu-button`)
	const mainMenu = document.querySelector(`.b-menu`)
	const menu = document.querySelector(`.${block}__mobile-menu`)

	if(document.querySelector(`.${block}__logo-up`)) {
		document.querySelectorAll(`.${block}__logo-up`).forEach((e) => {
			e.addEventListener('click', () => {
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
			})
		})
	}

	menuButton.addEventListener('click', () => {
		menu.classList.toggle(`_active`)
		menuButton.classList.toggle(`_active`)
		document.getElementsByTagName('body')[0].classList.toggle('hidden')
	})

	const headerBg = throttle(() => {
		if (window.pageYOffset > 10) {
			if (!header.classList.contains('_bg')) {
				header.classList.add('_bg')
				mainMenu.classList.add('_bg')
			}
		} else {
			if (header.classList.contains('_bg')) {
				header.classList.remove('_bg')
				mainMenu.classList.remove('_bg')
			}
		}
	}, 144);

	headerBg();

	const mobileHide = throttle(() => {
		if(window.innerWidth > 1279 && menu.classList.contains('_active')) {
			menu.classList.remove('_active')
			menuButton.classList.toggle(`_active`)
			document.getElementsByTagName('body')[0].classList.remove('hidden')
		}
	}, 144)

	window.addEventListener('resize', mobileHide)
	window.addEventListener('scroll', headerBg);
})